<template>
  <div
    class="tab-pane fade mt-3"
    :class="{ 'show active': $route.hash == '#history-review' }"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div
          v-for="i in 4"
          class="mb-4 p-3 card-product-review"
          :key="`product-review-skeleton-${i}`"
        >
          <div class="d-flex align-items-start">
            <b-skeleton-img class="rounded" width="120px" height="120px" />
            <div class="flex-grow-1 ml-2">
              <b-skeleton width="25%" class="mb-2" />
              <b-skeleton width="25%" class="mb-2" />
              <b-skeleton width="20%" class="mb-2" />
              <b-skeleton width="15%" class="mb-2" />
            </div>
          </div>
          <b-skeleton width="100%" class="mt-2" />
          <b-skeleton width="100%" class="mt-2" />
        </div>
      </template>
      <div class="text-center mt-5" v-if="productReviews.length == 0">
        <img src="@/assets/img/empty-review.png" alt="" width="300" height="300" />
        <h4 class="roboto">Data Review no available</h4>
      </div>
      <template v-else>
        <div
          class="mb-4 p-3 card-product-review"
          v-for="(review, index) in productReviews"
          :key="index"
        >
          <div class="row">
            <div class="col-md-12 d-flex">
              <router-link :to="`/product/${review.product.slug}`">
                <img
                  class="rounded"
                  :src="review.product.image_url"
                  alt=""
                  width="120"
                  height="120"
                  @error="replaceByDefaultImage"
                />
              </router-link>
              <div class="ml-2">
                <p class="m-0 bold">{{ review.product.brand_name }}</p>
                <router-link
                  :to="`/product/${review.product.slug}`"
                  class="text-black"
                >
                  {{ review.product.name }}
                </router-link>
                <div>
                  <fawesome-pro
                    v-for="i in 5"
                    :key="i"
                    :variant="review.rating - i > -1 ? 'fas' : 'far'"
                    icon="star"
                    class="text-warning pr-1"
                  />
                </div>
                <p class="review-date">
                  {{
                    $helpers.format.todayYesterday(
                      review.created_at,
                      "DD MMMM YYYY"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-2" v-if="review.comment">
            <div class="col-md-12">
              {{ review.comment.trim() }}
            </div>
          </div>
          <div class="row mt-2" v-if="review.tag != '' && review.tag != null">
            <div class="col-md-12 review-tag-list">
              <template v-for="(tag, index) of review.tag.split(';')">
                <div class="chip" v-if="tag.trim()" :key="index">
                  {{ tag }}
                </div>
              </template>
            </div>
          </div>
          <div class="row mt-2" v-if="review.review_images != ''">
            <div class="col-md-12 review-image-list">
              <img
                v-for="(image, imageIndex) in review.review_images"
                :key="imageIndex"
                :src="image"
                alt=""
                class="rounded"
                @click="
                  reviewImage = review.review_images;
                  reviewImageIndex = imageIndex;
                "
                v-b-modal.modal-image
              />
            </div>
          </div>
          <div class="row mt-2" v-if="review.review_feedbacks.length">
            <div class="col-md-6">
              <div
                class="merchant-feedback"
                v-for="(feedback, i) in review.review_feedbacks"
                :key="i"
              >
                <div class="merchant-profile">
                  <img src="@/assets/img/my-profile-default.png" alt="" />
                </div>
                <div class="info ml-2">
                  <b class="text-gold">{{ feedback.reviewer_name }}</b>
                  <div class="time">
                    {{ $helpers.format.todayYesterday(feedback.created_at, "DD MMMM YYYY") }}
                  </div>
                  <div>{{ feedback.comment }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-pagination
          v-if="productReviewRows > perPage"
          v-model="currentPage"
          :total-rows="productReviewRows"
          :per-page="perPage"
          @change="pageChange"
          align="right"
        />
      </template>
    </b-skeleton-wrapper>

    <ModalImage :items="reviewImage" :index="reviewImageIndex" />
  </div>
</template>

<script>
const ModalImage = () => import("@/components/modals/ModalImage.vue");

export default {
  name: "ListReviewedProduct",
  components: {
    ModalImage,
  },
  data() {
    return {
      isLoading: false,
      productReviews: [],

      // For pagination
      productReviewRows: 0,
      currentPage: 1,
      perPage: 5,

      // For modal image
      reviewImage: null,
      reviewImageIndex: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const res = await this.$api.product.getProductReviewProfileList(
          this.currentPage - 1,
          this.perPage
        );
        if (res.status === 200) {
          this.productReviews = res.data.data;
          this.productReviewRows = res.data.meta.total;
          this.$emit("scrollToTop");
        } else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    pageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    replaceByDefaultImage(event) {
      event.target.src = require("@/assets/img/default-image.png")
      event.target.classList.add("p-4", "default-image")
    },
  },
  watch: {
    "$route.hash"(val) {
      if (val == "#history-review") this.fetchData();
    }
  }
};
</script>

<style scoped>
.review-date {
  color: #919191;
  font-size: 14px;
}
.review-tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.review-image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.review-image-list img {
  max-width: 100px;
  max-height: 100px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
}
</style>